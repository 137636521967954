import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Field } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ErrorBoundary from 'exogen/components/error/error';
import TextField from 'shared/components/form/fields/text';
import { required } from 'validators';
import * as Yup from 'yup';

const requiredMessage = 'Required';

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required(requiredMessage)
    .nullable(),
  last_name: Yup.string()
    .required(requiredMessage)
    .nullable(),
  email: Yup.string()
    .email('Invalid email')
    .required(requiredMessage),
  npi_number: Yup.string().required(requiredMessage)
});

class AttestationEsignModal extends Component {
  render() {
    const { attestor, decline, initiatorEmail, physician, show } = this.props;

    const isInitialValid = validationSchema.isValidSync(physician);

    return (
      <Modal show={show} onHide={decline}>
        <Modal.Header closeButton>
          <Modal.Title>Attestation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            I, {attestor}, attest that the information I have provided is accurate and verifiable
            based on our medical records.
          </p>
          <p>
            <i className="fa fa-exclamation-triangle fa-fw has-error" />
            The email address used in routing this document for electronic signature must be
            uniquely associated with the provider. Shared/generic email accounts should not be used
            and could delay the intended treatment plan for your patient.
          </p>

          <ul>
            <li>
              After selecting accept the provider will receive an email with a link to Docusign. The
              provider should sign the form, and select Finish.
            </li>
            <li>
              Email for the provider cannot match the email of individual completing Section B.
            </li>
          </ul>

          <hr />

          <ErrorBoundary>
            <Formik
              displayName={'eCmnAttestationModal'}
              onSubmit={({ first_name, last_name, email, npi_number }, actions) => {
                this.props.setFieldValue('physician.first_name', first_name);
                this.props.setFieldValue('physician.last_name', last_name);
                this.props.setFieldValue('physician.email', email);
                this.props.setFieldValue('physician.npi_number', npi_number);
                actions.setSubmitting(false);
                this.props.handleAccept();
              }}
              isInitialValid={isInitialValid}
              initialValues={physician}
              validationSchema={validationSchema}
              enableReinitialize={true}
              validateOnChange={true}
              validateOnBlur={true}
              validate={vals => {
                if (
                  vals.email &&
                  initiatorEmail &&
                  vals.email.toLowerCase() === initiatorEmail.toLowerCase()
                ) {
                  return { email: 'Physician email cannot match initiator email' };
                }
                return {};
              }}
              render={({ submitForm, isValid }) => (
                <form>
                  <Row className="form-group">
                    <Col>
                      <h5>Physician Recipient</h5>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <Col>
                      <Field name="first_name" component={TextField} label="First Name*" />
                    </Col>
                    <Col>
                      <Field name="last_name" component={TextField} label="Last Name*" />
                    </Col>
                    <Col>
                      <Field name="email" component={TextField} label="Email*" />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <Col md={12}>
                      <div className="pull-right">
                        <Button
                          className="btn btn-primary btn--fw"
                          disabled={!isValid}
                          onClick={submitForm}
                        >
                          <span>
                            <i className="fa fa-check fa-fw" />
                            Accept
                          </span>
                        </Button>{' '}
                        <Button className="btn btn-default btn--fw" onClick={decline}>
                          <span>
                            <i className="fa fa-times fa-fw" />
                            Decline
                          </span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              )}
            />
          </ErrorBoundary>
        </Modal.Body>
        {/* <Modal.Footer>
          <button type="button" className="btn btn-default btn--fw" onClick={handleAccept}>
            <span>
              <i className="fa fa-check fa-fw" />
              Accept
            </span>
          </button>
          <button type="button" className="btn btn-default btn--fw" onClick={decline}>
            <span>
              <i className="fa fa-times fa-fw" />
              Decline
            </span>
          </button>
        </Modal.Footer> */}
      </Modal>
    );
  }
}

AttestationEsignModal.propTypes = {
  decline: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default AttestationEsignModal;
