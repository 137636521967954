import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class ModifyPracticeModal extends Component {
  render() {
    const { newPractice, currentPractice, updatePractice, close, show } = this.props;

    return (
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Practice Found in NPI Lookup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>We found your practice, {newPractice.name}, in the NPI Registry.</p>
          <div>
            <Row>
              <Col md={4}>Do you want to keep</Col>
              <Col>
                <strong>{currentPractice.name}</strong>
              </Col>
            </Row>
            <Row>
              <Col md={4}>Or update to</Col>{' '}
              <Col>
                <strong>{newPractice.name}</strong>?
              </Col>
            </Row>
          </div>
          <hr />

          <Row className="form-group">
            <Col md={12}>
              <div className="pull-right">
                <Button className="btn btn-primary btn--fw" onClick={close}>
                  Keep Existing
                </Button>{' '}
                <Button className="btn btn-default btn--fw" onClick={updatePractice}>
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

ModifyPracticeModal.propTypes = {
  close: PropTypes.func.isRequired,
  updatePractice: PropTypes.func.isRequired,
  newPractice: PropTypes.object.isRequired,
  currentPractice: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired
};

export default ModifyPracticeModal;
