import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Field } from 'formik';
import Button from 'react-bootstrap/Button';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as OrderActions from 'bv360/redux/actions/orders';
import { StandaloneSchema } from './validationSchema';

import PhoneField from 'shared/components/form/fields/phone';
import TextField from 'shared/components/form/fields/text';
import RadioGroupField from 'shared/components/form/fields/radio-group';
import DateField from 'shared/components/form/fields/date';

export default ({ error, executeRecaptcha, setQsValues, smsPending, today, doc_type }) => {
  const dispatch = useDispatch();
  const [options] = useState([
    {
      label: 'SMS',
      value: true
    },
    {
      label: `Email`,
      value: false
    }
  ]);

  return (
    <Formik
      displayName={'VerifyQuickSignSMs'}
      onSubmit={async vals => {
        dispatch(
          OrderActions.sendSmsCode(
            {
              ...vals,
              doc_type
            },
            true
          )
        );

        setQsValues({
          ...vals,
          quickSignDocType: doc_type
        });
      }}
      initialValues={{
        initiator: { first_name: '', last_name: '', email: '' },
        verifyBySms: true
      }}
      validationSchema={StandaloneSchema}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ setFieldValue, submitForm, errors, isValid }) => (
        <form>
          <Row>
            <Col>
              <p>
                <em>Please complete the following information to verify your identity:</em>
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={3} className="form-group">
              <Field
                label="Your First Name*"
                maxLength={32}
                name="initiator.first_name"
                disabled={smsPending}
                component={TextField}
              />
            </Col>
            <Col md={3} className="form-group">
              <Field
                label="Your Last Name*"
                maxLength={32}
                name="initiator.last_name"
                disabled={smsPending}
                component={TextField}
              />
            </Col>
            <Col md={3} className="form-group">
              <Field
                label="Your Mobile Number*"
                maxLength={32}
                name="phone"
                placeholder="(555) 555-5555"
                disabled={smsPending}
                component={PhoneField}
                showValidation
              />
            </Col>
            <Col md={3} className="form-group">
              <Field
                label="Your Email*"
                name="initiator.email"
                placeholder="Email"
                disabled={smsPending}
                component={TextField}
                showValidation
              />
            </Col>
            <Col md={3} className="form-group">
              <Field
                label="Patient Last Name*"
                maxLength={32}
                name="patientLastName"
                disabled={smsPending}
                component={TextField}
              />
            </Col>
            <Col md={3} className="form-group">
              <Field
                label="Patient DOB*"
                maxLength={5}
                name="patientDOB"
                maxDate={today}
                disabled={smsPending}
                component={DateField}
              />
            </Col>
            <Col md={3} className="form-group">
              <Field
                label="Provider Office Zip*"
                maxLength={5}
                name="officeZip"
                disabled={smsPending}
                component={TextField}
              />
            </Col>
            <Col md={3} className="form-group">
              <Field
                label="Verification Method*"
                maxLength={5}
                name="verifyBySms"
                disabled={smsPending}
                component={RadioGroupField}
                elems={options}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Col md={10}>
              {error && (
                <span className="has-error">
                  <i className="fa fa-exclamation-triangle fa-fw has-error" />
                  {error == 'Open order not found' ? 
                    <>
                    <strong>Patient/Office Combination not Found </strong>
                    <p>if you have any questions about completing the CMN, please contact your Order Manager, Sales Representative, or call the Bioventus CMN team at 901-341-2959.</p>
                    </>
                    : error}
                </span>
              )}
            </Col>
            <Col md={2}>
              <Button
                type="button"
                disabled={!isValid || smsPending}
                onClick={async vals => {
                  const recaptchaToken = await executeRecaptcha('quick_sign');
                  setFieldValue('recaptchaToken', recaptchaToken, true);
                  submitForm(vals);
                }}
                className="pull-right"
              >
                {smsPending ? <i className="fa fa-fw fa-circle-notch fa-spin" /> : 'Submit'}
              </Button>
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
