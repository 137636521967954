import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Field } from 'formik';
import Button from 'react-bootstrap/Button';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as OrderActions from 'bv360/redux/actions/orders';
import validationSchema from './validationSchema';

import PhoneField from 'shared/components/form/fields/phone';
import TextField from 'shared/components/form/fields/text';
import RadioGroupField from 'shared/components/form/fields/radio-group';

// componentDidMount() {
//   if (this.props.user) {
//     this.props.actions.goToLoggedInVersion(
//       urlStitch(URLS.QUICK_SIGN_LOGGED_IN, { uuid: this.props.match.params.uuid })
//     );
//   }
//   this.props.actions.getICD10s();
// }

// goToLogin = async () => {
//   await localforage.setItem('redirect', this.props.location.pathname);
//   this.props.actions.goToLoggedInVersion(URLS.LOGIN);
// };

export default ({ error, executeRecaptcha, setQsValues, smsPending, uuid }) => {
  const dispatch = useDispatch();
  const [options] = useState([
    {
      label: 'SMS',
      value: true
    },
    {
      label: `Email`,
      value: false
    }
  ]);

  const goToLogin = () => console.log('go to login');

  return (
    <Formik
      displayName={'VerifyQuickSignDetails'}
      onSubmit={async vals => {
        dispatch(
          OrderActions.sendSmsCode({
            ...vals,
            uuid
          })
        );
        setQsValues({ ...vals, uuid });
      }}
      initialValues={{ initiator: { first_name: null, last_name: null }, verifyBySms: true }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ setFieldValue, submitForm, errors, isValid }) => (
        <form>
          <Row className="form-group">
            <Col md={3}>
              <Button onClick={goToLogin}>Sign in</Button>
            </Col>
          </Row>

          <Row>
            <Col md={1} style={{ textAlign: 'center' }}>
              <strong>Or</strong>
            </Col>
            <Col md={11}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <em>Please complete the following information to verify your identity:</em>
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={3} className="form-group">
              <Field
                label="Your First Name*"
                maxLength={32}
                name="initiator.first_name"
                disabled={smsPending}
                component={TextField}
              />
            </Col>
            <Col md={3} className="form-group">
              <Field
                label="Your Last Name*"
                maxLength={32}
                name="initiator.last_name"
                disabled={smsPending}
                component={TextField}
              />
            </Col>
            <Col md={3} className="form-group">
              <Field
                label="Your Mobile Number*"
                maxLength={32}
                name="phone"
                placeholder="(555) 555-5555"
                disabled={smsPending}
                component={PhoneField}
                showValidation
              />
            </Col>
            <Col md={3} className="form-group">
              <Field
                label="Provider Office Zip*"
                maxLength={5}
                name="officeZip"
                disabled={smsPending}
                component={TextField}
              />
            </Col>
            <Col md={3} className="form-group">
              <Field
                label="Verification Method*"
                maxLength={5}
                name="verifyBySms"
                disabled={smsPending}
                component={RadioGroupField}
                elems={options}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Col md={10}>
              {error && (
                <span className="has-error">
                  <i className="fa fa-exclamation-triangle fa-fw has-error" />
                  {error}
                </span>
              )}
            </Col>
            <Col md={2}>
              <Button
                type="button"
                disabled={!isValid || smsPending}
                onClick={async vals => {
                  const recaptchaToken = await executeRecaptcha('quick_sign');
                  setFieldValue('recaptchaToken', recaptchaToken, true);
                  submitForm(vals);
                }}
                className="pull-right"
              >
                {smsPending ? <i className="fa fa-fw fa-circle-notch fa-spin" /> : 'Submit'}
              </Button>
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
